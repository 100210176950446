<template>
<div>
    <div v-if="received_messages && received_messages.length <= 0" class="p-3 bg-light small">
        {{ translate('no_messages') }}
    </div>
    <b-list-group-item v-if="show_message_actions" class="list-group-item-accent-danger shadow-1 bg-white small p-0">
        <q-btn no-caps dense color="danger" @click.prevent="showDeleteSelectedMessagesPanel" flat>
            <i class="fas fa-trash mr-1"></i>{{ translate('delete') }}
            <q-badge class="ml-2 p-1" color="info">{{ selected_messages.length }}</q-badge>
        </q-btn>
        <q-btn class="pull-right" no-caps dense @click.prevent="cancelMessageActions" flat>
            <i class="fas fa-close mr-1"></i>{{ translate('cancel') }}
        </q-btn>
    </b-list-group-item>
    <div v-if="received_messages && received_messages.length > 0" class="card_scroll-app-messages wrapper_received_messages-list">
        <b-list-group-item 
            @click.stop="showMessageDetailsPanel(message.id)" v-for="(message,index) in received_messages" 
            :key="'app-message-' + index " href="#" 
            class="list-group-item-accent-primary pl-2 pl-2 pt-1 pb-1" 
            :class="setMessageRowClasses(message)">
            <q-item class="p-0">
                <q-item-section class="pl-0 pr-2" side>
                    <q-checkbox dense @input="toggleMessageActions(message.id)" :val="message.id" v-model="selected_messages" />
                </q-item-section>
                <q-item-section>
                    <small class="text-right text-muted">{{ message.created_at.slice(0,-3) }}</small>
                    <strong :class="message && message.is_read == false ? 'text-dark' : ''">{{ translate('message_from') }}: {{ message.sender_name }}</strong>
                    <span><i :class="message && message.is_read == false ? 'icon-envelope' : 'icon-envelope-open'"></i>&nbsp;&nbsp;{{ message.subject }}</span>
                </q-item-section>
            </q-item>
        </b-list-group-item>
    </div>
    <infinite-loading :identifier="'received-messages-' + infReceivedMessages" slot="append" @infinite="getReceivedMessages" />
</div>
</template>

<script>
import { eventBus } from '../../../main'
import DeleteMessages from './DeleteMessages'
import MessageDetails from '../show/MessageDetails'

export default {
    name: 'ReceivedMessages',
    data: function () {
        return {
            show_message_actions: false,
            selected_messages: [],
            page_received_messages: 1,
            received_messages: [],
            state: {},
            infReceivedMessages: +new Date(),
        }
    },
    mounted: function () {
        this.$on('transition', this.resetReceivedList())
        eventBus.$on('update_all_messages', () => {
            this.resetReceivedList()
        })
        eventBus.$on('delete_selected_messages', (ids) => {
            this.deleteSelectedMessagesFromDOM(ids)
        })
        eventBus.$on('update_is_read', (message) => {
            this.updateMessageIsRead(message)
        })
        // Register the event listener when the Vue component is ready.
        window.addEventListener('resize', this.setScrollWrapperHeight)
    },
    beforeDestroy: function () {
        // Unregister the event listener before destroying this Vue instance.
        window.removeEventListener('resize', this.setScrollWrapperHeight)
    },
    methods: {
        setScrollWrapperHeight: _.debounce(function () {
            let windowHeight = this.$q.screen.height
            if (document.querySelector('.card_scroll-app-messages')) {
                let wrapperOffsetTop = document.querySelector('.card_scroll-app-messages').getBoundingClientRect().top
                document.querySelector('.card_scroll-app-messages').style.height = (windowHeight - wrapperOffsetTop) + 'px'
            }
        }, 200),
        deleteSelectedMessagesFromDOM: function (ids) {
            if (ids && ids.length) {
                ids.forEach(id => {
                    // Find index of specific object using findIndex method,
                    var objIndex = this.received_messages.findIndex((obj => obj.id === id))
                    // and remove the element from the messages array.
                    this.received_messages.splice(objIndex, 1)
                });
            }
            this.cancelMessageActions()
        },
        setMessageRowClasses: function (message) {
            var classes = ''
            classes += message.is_read == false ? 'text-bold' : 'text-normal'
            classes += this.selected_messages.includes(message.id) ? ' selected-message' : ''
            return classes
        },
        showMessageActions: function () {
            this.show_message_actions = true
        },
        hideMessageActions: function () {
            this.show_message_actions = false
        },
        updateMessageIsRead: function (message) {
            var objIndex = this.received_messages.findIndex((obj => obj.id === message.id))
            // And update is_read for the given message in list.
            if (this.received_messages[objIndex]) {
                this.received_messages[objIndex]['is_read'] = true
            }
        },
        deleteSelectedMessagesFromDOM: function (ids) {
            if (ids && ids.length) {
                ids.forEach(id => {
                    // Find index of specific object using findIndex method,
                    var objIndex = this.received_messages.findIndex((obj => obj.id === id))
                    // and remove the element from the messages array.
                    this.received_messages.splice(objIndex, 1)
                });
            }
            this.cancelMessageActions()
        },
        toggleMessageActions: function () {
            if (this.selected_messages.length > 0) {
                this.showMessageActions()
            } else {
                this.hideMessageActions()
            }
            this.setScrollWrapperHeight()
        },
        cancelMessageActions: function () {
            this.hideMessageActions()
            this.selected_messages = []
        },
        resetReceivedList: function () {
            this.page_received_messages = 1
            this.received_messages = []
            this.infReceivedMessages++
            this.setScrollWrapperHeight()
        },
        getReceivedMessages: function ($state) {
            // Get Received Messages.
            var url = baseUrl + 'app_messages?page=' + this.page_received_messages + '&items_per_page=' + this.$items_per_page
            var headers = {
                "Authorization": "Bearer " + this.accessToken(),
            }
            this.state = $state
            axios.get(url, {
                headers: headers
            }).then(response => {
                $state = this.state
                if (response.data.items && response.data.items.length > 0) {
                    this.received_messages.push(...response.data.items)
                }
                if (response.data.next_page === true) {
                    this.page_received_messages++
                    $state.loaded()
                } else {
                    $state.complete()
                }
            }).catch(error => {
                // console.log(error)
            })
        },
        showMessageDetailsPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: MessageDetails,
                props: {
                    message_id: id,
                    delete_route: baseUrl + 'app_messages/delete_received'
                }
            })
        },
        showDeleteSelectedMessagesPanel: function () {
            const panelInstance = this.$showPanel({
                component: DeleteMessages,
                props: {
                    ids: this.selected_messages,
                    delete_route: baseUrl + 'app_messages/delete_received'
                }
            })
            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetReceivedList()
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.card_scroll-app-messages {
    max-height: 75vh;
    overflow-y: auto;
}
.selected-message {
    background-color: var(--q-color-info);
    color: #fff;
    font-weight: 700;
    .text-muted {
        color: #fff!important;
    }
}
</style>
