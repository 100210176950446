selected_recipients<!-- [+] Show and filter Userss. -->
<template>
<div class="fit users-container">
    <div>
        <q-item class="list-item pt-2 pb-2 message-to full-width" clickable @click="toggleUsers()">
            <q-item-section class="p-0">
                <span>
                    {{ translate('send_message_to') }}
                    <span v-if="showUsers" class="text-muted pull-right">{{ translate('hide_list') }} <q-icon name="fa fa-angle-up" /></span>
                </span>
                <span v-if="selected_recipients">
                    <strong @click.stop.prevent="null" class="mr-1 mt-1 mb-1 pl-1 text-muted selected-user bg-white" v-for="(user,i) in selected_users" :key="'selected-user-' + i + '-' + user.id">
                        <span class="user-name mr-1">{{ user.name }}</span>
                        <q-btn flat dense size="0.65rem" icon="close" @click.stop.prevent="removeUserFromSelected(user.id)" />
                    </strong>
                </span>
            </q-item-section>
        </q-item>
        <div v-if="showUsers" class="position-relative p-0 no-focus-style">
            <q-separator />
            <div class="row m-0 align-items-center">
                <div class="col-xs-12  text-right">
                    <q-input class="search-input" @input="isTypingUser = true" v-model="searchUser" id="filternameuser" type="text" :placeholder="translate('find_user')" :dense="true">
                        <template v-slot:prepend>
                            <q-icon name="search" />
                        </template>
                    </q-input>
                </div>
            </div>
            <b-card-body class="bg-light m-0 pt-0 pb-0 users-list-container">
                <div class="card_scroll-users">
                    <div class="wrapper-users-list">
                        <q-item class="full-width list-item" tag="label" clickable v-ripple v-for="(user,i) in users" :key="'user-' + i + '-' + user.id">
                            <q-item-section avatar>
                                <q-avatar class="cursor-pointer shadow-2">
                                    <img v-if="user.image_link" :src="user.image_link">
                                    <i v-else class="fas fa-user fa-1x text-muted"></i>
                                </q-avatar>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label class="small text-muted">{{ translate(user.user_type) }}</q-item-label>
                                <q-item-label>{{ user.name }} &lt;{{ user.email }}&gt;</q-item-label>
                            </q-item-section>
                            <q-item-section side>
                                <q-checkbox @input="updateSelectedUsers(user.id, user.name)" size="md" flat color="primary" :id="user.id" :val="user.id" v-model="selected_recipients" />
                            </q-item-section>
                        </q-item>
                    </div>
                    <infinite-loading :identifier="'infLoadingMessageUsers' + infMessageUsers" slot="append" @infinite="getUsers" />
                </div>
                <q-item class="p-0">
                    <q-item-section>
                    </q-item-section>
                    <q-item-section side>
                        <q-btn @click.stop="toggleUsers()" flat no-caps text-color="dark" color="light" v-if="showUsers" class="pull-right">{{ translate('hide_list') }} <q-icon name="fa fa-angle-up" /></q-btn>
                    </q-item-section>
                </q-item>
            </b-card-body>
        </div>
    </div>
</div>
</template>
<!-- [-] Show and filter Users. -->

<script>
import { eventBus } from '../../../main'

export default {
    name: 'UsersList',
    props: [],
    watch: {
        searchUser: _.debounce(function () {
            this.isTypingUser = false
        }, 200),
        isTypingUser: function (value) {
            if (!value) {
                this.resetMainList()
            }
        },
    },
    data: function () {
        return {
            baseUrl: baseUrl,
            showUsers: false,
            state: {},
            infMessageUsers: +new Date(),
            users: [],
            page_user: 1,
            isTypingUser: false,
            searchUser: '',
            selected_recipients: [],
            selected_users: [],
            schedule: {},
        }
    },
    methods: {
        removeUserFromSelected: function (id) {            
            // Find index of specific object using findIndex method,
            var objIndex = this.selected_users.findIndex((obj => obj.id === id))
            // and remove the element from the selected_users array.
            this.selected_users.splice(objIndex, 1)
            // Find index of specific object using findIndex method,
            var objIndex = this.selected_recipients.findIndex((obj => obj.id === id))
            // and remove the element from the selected_recipients array.
            this.selected_recipients.splice(objIndex, 1)
        },
        toggleUsers: function () {
            if (this.showUsers) {
                this.showUsers = false
            } else {
                this.showUsers = true
                this.resetMainList()
            }
        },
        hideUsers: function () {
            this.showUsers = false
        },
        updateSelectedUsers: function (user_id, user_name) {
            var selected_users = []
            this.selected_recipients.map(recipient => {
                if (user_id === recipient) {
                    selected_users.push({id: user_id, name: user_name})
                }
            })
            if (this.selected_recipients.filter(id => user_id === id).length) {
                this.selected_users.push(...selected_users)
            } else {
                // Find index of specific object using findIndex method,
                var objIndex = this.selected_users.findIndex((obj => obj.id === user_id))
                // and remove the element from the products array.
                this.selected_users.splice(objIndex, 1)
            }
            
            eventBus.$emit('update_recipients', this.selected_recipients)
        },
        resetMainList: function () {
            this.page_user = 1
            this.users = []
            this.infMessageUsers++
            this.selected_users = this.selected_users
        },
        getUsers: function ($state) {
            var url = baseUrl +
                'users?page=' + this.page_user +
                '&items_per_page=' + this.$items_per_page
            
            if (this.searchUser) {
                url += '&search=' + this.searchUser
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            
            this.state = $state
            let users = this.users
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        users.push(...response.data.items.map(entity => {
                            let array = entity
                            array['image_link'] = entity.image_link ? baseUrl + entity.image_link : null
                            return array
                        }))
                    }
                    if (response.data.next_page === true) {
                        this.page_user++
                        $state.loaded()
                    } else {
                        this.users = users
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })

        },
    }
}
</script>

<style lang="scss" scoped>
.users-list-container {
    position: absolute;
    width: 100%;
    left: 0;
    top: auto;
    z-index: 1000;
    border: 1px solid rgba(0, 0, 0, .1);
    border-top: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .1);

    .card_scroll-users {
        overflow-x: hidden;
        max-height: 50vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: 1px solid rgba(0, 0, 0, 0.16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &.message-to {
        padding-left: 12px;
        padding-right: 12px;
    }

    &:hover {
        border-color: rgba(0, 0, 0, .5);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }

    &.selected-user {
        border-color: var(--primary)!important;
    }
}

body.desktop .message-to.q-hoverable > .q-focus-helper {
    opacity: 0!important;
}

body .search-input {
    margin-left: 15px !important;
    margin-right: 15px !important;
    max-width: calc(100% - 30px);
}
@media screen and (max-width: 767px) {
    body .search-input {
        margin-top: 10px;
        margin-left: 15px !important;
        margin-right: 0 !important;
    }
    .card_scroll-users {
        max-height: calc(280px + 3.75rem);
    }
}
.q-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.selected-user {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.16);
 
    &:hover {
        border-color: #000!important;
    }
    .q-btn {
        &:hover {
            color: var(--q-color-negative)!important;
        }
    }
}
</style>
