<template>
<div>
    <q-tabs class="no-controls list-group-item-accent-secondary" no-caps v-model="tab" align="left" indicator-color="primary" active-color="primary">
        <q-tab :class="tab === 'upcoming-events' ? '' : ''" name="upcoming-events">
            <q-icon class="pt-3 mb-3 pr-1 ml-1" size="1.25rem" name="icon-calendar">
                <q-badge v-if="upcomingEventsCount" color="indigo-6" floating>
                    <strong>{{ upcomingEventsCount }}</strong>
                </q-badge>
            </q-icon>
        </q-tab>
        <q-tab :class="tab === 'notifications' ? '' : ''" name="notifications">
            <q-icon class="pt-3 mb-3 pr-1 ml-1" size="1.25rem" name="icon-bell">
                <q-badge v-if="notificationsCount" color="negative" floating>
                    <strong>{{ notificationsCount }}</strong>
                </q-badge>
            </q-icon>
        </q-tab>
        <q-tab :class="tab === 'messages' ? '' : ''" name="messages">
            <q-icon class="pt-3 mb-3 pr-1 ml-1" size="1.25rem" name="icon-envelope-open">
                <q-badge v-if="messagesCount" color="primary" floating>
                    <strong>{{ messagesCount }}</strong>
                </q-badge>
            </q-icon>
        </q-tab>
    </q-tabs>
    <q-separator />
    <q-tab-panels v-model="tab">
        <q-tab-panel class="p-0" name="upcoming-events">
            <upcoming-events />
        </q-tab-panel>
        <q-tab-panel class="p-0" name="notifications">
            <notifications />
        </q-tab-panel>
        <q-tab-panel class="p-0" name="messages">
            <messages />
        </q-tab-panel>
    </q-tab-panels>
</div>
</template>

<script>
import { eventBus } from '../main'
import UpcomingEvents from '../views/upcoming-events/UpcomingEvents'
import Notifications from '../views/notifications/Notifications'
import Messages from '../views/messages/Messages'

export default {
    name: 'DefaultAside',
    components: {
        'upcoming-events': UpcomingEvents,
        'notifications': Notifications,
        'messages': Messages,
    },
    data: function () {
        return {
            tab: null,
            upcomingEventsCount: null,
            notificationsCount: null,
            messagesCount: null
        }
    },
    mounted: function () {
        eventBus.$on('update_info_panel_badge_count_upcoming_events', (badge) => {
            this.updateUpcomingEventsBadgeCount(badge)
        })
        eventBus.$on('update_info_panel_badge_count_notifications', (badge) => {
            this.updateNotificationsBadgeCount(badge)
        })
        eventBus.$on('update_info_panel_badge_count_app_messages', (badge) => {
            this.updateAppMessagesBadgeCount(badge)
        })
        eventBus.$on('update_badges_count', () => {
            this.getBadgesCount()
        })
        eventBus.$on('get_badges_count', () => {
            this.getBadgesCount()
        })
    },
    methods: {
        updateUpcomingEventsBadgeCount: function (badge) {
            this.upcomingEventsCount = badge.count ? badge.count : null
        },
        updateNotificationsBadgeCount: function (badge) {
            this.notificationsCount = badge.count ? badge.count : null
        },
        updateAppMessagesBadgeCount: function (badge) {
            this.messagesCount = badge.count ? badge.count : null
        },
        getBadgesCount: function () {
            var url = baseUrl + 'info_panel/badges_count'
            var headers = {
                "Authorization": "Bearer " + this.accessToken(),
            }
            axios.get(url, {
                headers: headers
            }).then(response => {
                if (response.data) {
                    if (response.data.notifications_badge_count !== null) {
                        // Update upcoming events badge count.
                        eventBus.$emit('update_info_panel_badge_count_upcoming_events', {count: response.data.upcoming_events_badge_count})
                    }
                    if (response.data.notifications_badge_count !== null) {
                        // Update notifications badge count.
                        eventBus.$emit('update_info_panel_badge_count_notifications', {count: response.data.notifications_badge_count})
                    }
                    if (response.data.app_messages_badge_count !== null) {
                        // Update notifications badge count.
                        eventBus.$emit('update_info_panel_badge_count_app_messages', {count: response.data.app_messages_badge_count})
                    }
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }
}
</script>
