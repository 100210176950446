<template>
<div>
    <template v-if="notifications && notifications.length <= 0">
        <b-list-group class="list-group-accent">
            <b-list-group-item class="list-group-item-accent-secondary bg-light text-center text-uppercase small text-muted">
                <span class="font-weight-bold">
                    {{ translate('no_notifications') }}
                </span>
            </b-list-group-item>
        </b-list-group>
    </template>
    <template v-else>
        <b-list-group class="wrapper_notifications-list">
            <b-list-group-item class="list-group-item-accent-secondary bg-light text-center text-uppercase small text-muted">
                <span class="font-weight-bold">
                    {{ translate('notifications') }}
                </span>
            </b-list-group-item>
            <div class="card_scroll-notifications">
                <b-list-group-item v-for="(notification,index) in notifications" :key="'app-notification-' + index " href="#" class="list-group-item-accent-primary">
                    <i class="icon-bubble"></i>&nbsp;&nbsp;{{ notification.subject }}
                </b-list-group-item>
            </div>
        </b-list-group>
    </template>
    <infinite-loading :identifier="'notifications-' + infNotifications" slot="append" @infinite="getNotifications" />
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../main'

export default {
    name: 'NotificationsCustomer',
    data: function () {
        return {
            page: 1,
            notifications: [],
            state: {},
            infNotifications: +new Date(),
            current_language: this.$appSettings.lang,
        }
    },
    mounted: function () {
        this.$on('transition', () => {
            this.resetMainList()
        })
        eventBus.$on('current_language', (lang) => {
            this.updateCurrentLanguage(lang)
        })

        this.setScrollWrapperHeight()
    
        // Register the event listener when the Vue component is ready.
        window.addEventListener('resize', this.setScrollWrapperHeight)
    },
    beforeDestroy: function () {
        // Unregister the event listener before destroying this Vue instance.
        window.removeEventListener('resize', this.setScrollWrapperHeight)
    },
    methods: {
        setScrollWrapperHeight: _.debounce(function () {
            let windowHeight = this.$q.screen.height
            if (document.querySelector('.card_scroll-notifications')) {
                let wrapperOffsetTop = document.querySelector('.card_scroll-notifications').getBoundingClientRect().top
                document.querySelector('.card_scroll-notifications').style.maxHeight = (windowHeight - wrapperOffsetTop) + 'px'
            }
        }, 300),
        updateCurrentLanguage: function (lang) {
            this.resetMainList(lang)
        },
        resetMainList: function (lang) {
            if (lang) {
                this.current_language = lang
            }
            this.page = 1
            this.notifications = []
            this.infNotifications++
            this.setScrollWrapperHeight()
        },
        getNotifications: function ($state) {
            // Get Notifications.
            var url = baseUrl + 'notifications?lang=' + this.current_language + '&page=' + this.page + '&items_per_page=' + this.$items_per_page
            var headers = {
                "Authorization": "Bearer " + this.accessToken(),
            }
            this.state = $state
            axios.get(url, {
                headers: headers
            }).then(response => {
                $state = this.state
                if (response.data.items && response.data.items.length > 0) {
                    this.notifications.push(...response.data.items)
                }
                if (response.data.next_page === true) {
                    this.page++
                    $state.loaded()
                } else {
                    $state.complete()
                }
            }).catch(error => {
                // console.log(error)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.card_scroll-notifications {
    max-height: 74vh;
    overflow-y: auto;
}
</style>
