<template>
<div class="upcoming-events-container">
    <upcoming-events-worker v-if="showUpcomingEventsWorker"></upcoming-events-worker>
    <upcoming-events-customer v-if="showUpcomingEventsCustomer"></upcoming-events-customer>
</div>
</template>

<script>
import UpcomingEventsWorker from './UpcomingEventsWorker';
import UpcomingEventsCustomer from './UpcomingEventsCustomer';

export default {
    name: 'UpcomingEvents',
    components: {
        'upcoming-events-worker': UpcomingEventsWorker,
        'upcoming-events-customer': UpcomingEventsCustomer
    },
    data: function () {
        return {
            enableCard: false,
        }
    },
    computed: {
        showUpcomingEventsWorker: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return this.enableCard;
            }
        },
        showUpcomingEventsCustomer: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return !this.enableCard;
            }
        }
    },
}
</script>
