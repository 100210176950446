<template>
<div class="notifications-container">
    <notifications-worker v-if="showNotificationsWorker"></notifications-worker>
    <notifications-customer v-if="showNotificationsCustomer"></notifications-customer>
</div>
</template>

<script>
import NotificationsWorker from './NotificationsWorker';
import NotificationsCustomer from './NotificationsCustomer';

export default {
    name: 'Notifications',
    components: {
        'notifications-worker': NotificationsWorker,
        'notifications-customer': NotificationsCustomer
    },
    data: function () {
        return {
            enableCard: false,
        }
    },
    computed: {
        showNotificationsWorker: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return this.enableCard;
            }
        },
        showNotificationsCustomer: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return !this.enableCard;
            }
        }
    },
}
</script>
