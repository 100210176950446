<template>
    <div class="row m-0 align-items-center">
        <!--footer-->
        <div class="col-md">
            <div class="row align-items-center m-0">
                <div class="col-auto col-md-auto p-0">
                    <a href="/" @click.prevent.stop="getVersion">Dynamic Calendar</a>
                </div>
                <div class="col-auto col-md-auto p-0">
                    <span class="ml-1 mr-1">&copy; {{ currentYear }}</span>
                </div>
                <div class="col-md-auto p-0">
                    <q-chip dense v-if="version && version.backend" square removable v-model="version.backend" color="white" text-color="dark" class="shadow-2 pl-0">
                        <strong>{{ version.backend }}</strong>
                    </q-chip>
                </div>
            </div>
        </div>
        <div class="col-md-auto">
            <span class="mr-1">Powered by</span>
            <a href="https://neobytesolutions.com">NeobyteSolutions</a>
        </div>
    </div>
</template>

<script>
import { eventBus } from '../main'

export default {
    name: "CustomFooter",
    data: function () {
        return {
            currentYear: new Date().getFullYear(),
            version: {
                backend: ''
            },
        }
    },
    mounted: function () {
        eventBus.$on('set_backend_version', (version) => {
            this.setBackendVersion(version)
        })
    },
    methods: {
        setBackendVersion: function (version) {
            if (version) {
                this.version['backend'] = this.translate('backend_version') + ': ' + version
            }
        },
        getVersion: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            }
            var url = baseUrl + 'version'
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.status) {
                        eventBus.$emit('set_backend_version', response.data.version)
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
    },
}
</script>