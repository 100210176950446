<template>
<div class="fit sticky-form-actions edit-panel">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-item class="p-0" style="min-height: 32px;">
                    <q-item-section side>
                        <span>
                            <q-icon name="icon-envelope-open" />
                            {{ translate('message') }}
                        </span>
                    </q-item-section>
                    <q-item-section></q-item-section>
                    <q-item-section side>
                        <q-btn no-caps dense color="danger" @click.prevent="showDeleteSelectedMessagesPanel" icon="delete" :label="translate('delete')" flat />
                    </q-item-section>
                </q-item>
            </template>
            <b-list-group v-if="message" class="list-group-accent">
                <b-list-group-item class="pl-3 pr-3 pt-0 pb-2">
                    <h3 class="text-h6 m-0">{{ message.subject }}</h3>
                    <small class="text-muted display-block">{{ message.created_at ? message.created_at.slice(0, -3) : '' }}</small>
                </b-list-group-item>
                <q-separator />
                <b-list-group-item class="text-bold p-0">
                    <q-item class="align-items-center  pl-3 pr-3 pt-2 pb-2">
                        <q-item-section>
                            <template v-if="message.sender_name">
                                <span><small class="text-muted">{{ translate('message_from') }}: </small>{{ message.sender_name }}</span>
                            </template>
                            <template v-if="recipient_name">
                                <span><small class="text-muted">{{ translate('message_to') }}: </small>{{ recipient_name }}</span>
                            </template>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
                <q-separator />
                <b-list-group-item class="pl-3 pr-3 pt-2 pb-2">
                    <small class="display-block text-muted">{{ translate('message') }}</small>
                    <q-card square class="shadow-0 mb-3 pre-formatted">
                        {{ message.message }}
                    </q-card>
                </b-list-group-item>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side></q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../../main'
import DeleteMessage from '../forms/DeleteMessages'

export default {
    name: 'MessageDetails',
    props: ['message_id', 'delete_route', 'recipient_name'],
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            message: {},
            sender: null
        }
    },
    created: function () {
        this.getMessageDetails()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions') ? document.querySelector('.form-actions').clientHeight : 0
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs') ? document.querySelector('.slideout .tabs').clientWidth : 0
        }, 200),
        showDeleteSelectedMessagesPanel: function () {
            const panelInstance = this.$showPanel({
                component: DeleteMessage,
                props: {
                    ids: [this.message_id],
                    delete_route: this.delete_route
                }
            })
            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.$emit('closePanel', {
                        status: true
                    })
                }
                // Fix issue from slideout closing panel.
                document.querySelector('body').classList.remove('slideout-panel-open')
            })
        },
        getMessageDetails: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            }
            var message_id = this.message_id
            var url = baseUrl + 'app_messages/' + message_id

            axios.get(url, {
                headers: headers
            }).then(response => {
                this.message = response.data.item

                // Update message is_read.
                eventBus.$emit('update_is_read', {id: message_id})
                // Update badges count.
                eventBus.$emit('update_badges_count')
            }).catch(error => {
                // console.log(error)
            })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>