<template>
<div class="messages-container">
    <messages-worker v-if="showMessagesWorker"></messages-worker>
    <messages-customer v-if="showMessagesCustomer"></messages-customer>
</div>
</template>

<script>
import MessagesWorker from './MessagesWorker';
import MessagesCustomer from './MessagesCustomer';

export default {
    name: 'Messages',
    components: {
        'messages-worker': MessagesWorker,
        'messages-customer': MessagesCustomer
    },
    data: function () {
        return {
            enableCard: false,
        }
    },
    computed: {
        showMessagesWorker: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return this.enableCard;
            }
        },
        showMessagesCustomer: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return !this.enableCard;
            }
        }
    },
}
</script>
