<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <div class="mt-1">
                    <q-icon class="mr-1" name="icon-envelope" /> {{ translate('send_message') }}
                </div>
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item class="p-0">
                    <!-- [+] Users. -->
                    <users-list class="p-3" />
                    <!-- [-] Users. -->

                    <!-- [+] Subject. -->
                    <q-item class="p-0 mb-3">
                        <q-item-section>
                            <q-input square class="m-0" color="primary" type="text" :placeholder="translate(translate('message_subject'))" v-model="message.subject" />
                        </q-item-section>
                    </q-item>
                    <!-- [-] Subject. -->

                    <!-- [+] Message. -->
                    <q-item class="p-0 mb-3">
                        <q-item-section>
                            <q-input square class="m-0" color="primary" type="textarea" :placeholder="translate('message_body')" v-model="message.message" />
                        </q-item-section>
                    </q-item>
                    <!-- [-] Message. -->
                </b-list-group-item>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn @click.prevent="sendMessage" type="button" no-caps color="primary">
                                <q-icon size="1rem" name="send" class="mr-1" /> {{ translate('send') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../../main'
import UsersList from '../template-parts/UsersList'

export default {
    name: 'SendMessage',
    components: {
        'users-list': UsersList
    },
    computed: {
        message_placeholder: function () {
            return this.translate('message_body')
        }
    },
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            message: {
                recipient_ids: [],
                subject: null,
                message: null
            }
        }
    },
    created: function () {
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        eventBus.$on('update_recipients', (ids) => {
            this.updateRecipientIds(ids)
        })
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        updateRecipientIds: function (ids) {            
            this.message.recipient_ids = ids
        },
        getMessagePlaceholder: function () {
            return this.message_placeholder
        },
        sendMessage: function () {
            var url = baseUrl + 'app_messages'
            var data = {
                recipient_ids: this.message.recipient_ids,
                subject: this.message.subject,
                message: this.message.message
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            }
            
            axios.post(url, data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'send_message' + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        translated_message = this.translate('sent_messages_count') + ': ' + response.data.sent_messages
                        if (response.data.unsent_messages) {
                            translated_message = translated_message + ', ' + this.translate('unsent_messages') + ': ' + response.data.unsent_messages
                        }

                        eventBus.$emit('update_all_messages')

                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    this.$toasted.error('An error has occured', {
                        duration: this.$toasted_duration
                    })
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>
