<template>
<AppHeaderDropdown right no-caret>
    <template slot="header">
        <q-avatar class="shadow-0 dropdown-avatar">
            <img v-if="profile_image" class="img-avatar" :src="profile_image">
            <i v-else class="fas fa-user fa-1x text-muted"></i>
        </q-avatar>
    </template>
    <template slot="dropdown">
        <b-dropdown-header tag="div" class="text-center"><strong>Account</strong>
        </b-dropdown-header>
        <router-link :to="{name:'UserSettings'}" class="text-primary dropdown-item">
            <i class="fa fa-wrench" />
            {{translate('settings')}}
        </router-link>

        <router-link :to="{name:'ChangePassword'}" class="text-primary dropdown-item">
            <i class="fa fa-unlock" />
            {{translate('change_password')}}
        </router-link>

        <router-link :to="{name:'MyProfile'}" class="text-primary dropdown-item">
            <i class="fa fa-user" />
            {{translate('my_profile')}}
        </router-link>

        <router-link @click.native="logoutUser()" :to="{name:'Login'}" class="text-primary dropdown-item">
            <i class="fa fa-power-off" />
            {{translate('logout')}}
        </router-link>
    </template>

</AppHeaderDropdown>
</template>

<script>
import * as jwt_decode from 'jwt-decode'
import { eventBus } from '../main'
import {
    HeaderDropdown as AppHeaderDropdown
} from '@coreui/vue'

export default {
    name: 'DefaultHeaderDropdownAccnt',
    components: {
        AppHeaderDropdown
    },
    data: () => {
        return {
            itemsCount: 42,
            profile_image: null
        }
    },
    created: function () {
        eventBus.$on('updateCurrentUserProfileImage', () => this.getProfileImage())
        this.getProfileImage()
    },
    methods: {
        getProfileImage: function () {
            var url = ''
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            var decoded = jwt_decode(this.accessToken())
            if (this.$appSettings.currentUserType() === 'worker') {
                var worker_id = decoded.identity.worker_id
                url = baseUrl + 'profile_images/link/worker/' + worker_id
            }
            if (this.$appSettings.currentUserType() === 'customer') {
                var customer_id = decoded.identity.customer_id
                url = baseUrl + 'profile_images/link/customer/' + customer_id
            }

            if (url) {
                axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.profile_image = response.data.image_link ? baseUrl + response.data.image_link : null
                })
                .catch(function (error) {
                    console.log(error)
                })
            }
        },
        logoutUser() {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            };
            axios.delete(baseUrl + 'auth/logout', {
                    headers: headers
                })
                .then(response => {
                    if (response.data.status) {
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('refresh_token');
                        if (this.$appSettings.userNav) {
                            this.$appSettings.userNav = []
                        }

                        // Redirect to 'Login' after logout.
                        this.$router.push({
                            name: 'Login'
                        }, undefined, () => { 
                            location.href = this.$route.hash 
                        })
                    }
                })
                .catch(error => {
                    var msg = error.response.data.msg;
                    if (msg != undefined) {
                        this.msg = msg;
                    }
                })
        },
        sl() {
            window.app_config.lang = 'cf'
        }
    }
}
</script>

<style scoped>
.app-header .dropdown-item {
    min-width: 100%!important;
    max-width: 100%;
    border: none;
}
.dropdown-item.router-link-exact-active.active {
    color: #fff!important;
}
.dropdown-avatar {
    font-size: 38px;
}
</style>