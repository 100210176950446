<template>
<div class="app">
    <AppHeader fixed>
        <SidebarToggler class="d-lg-none" display="md" mobile />
        <b-link class="navbar-brand" :to="{name: brand_link}">
            <img class="navbar-brand-full" :src="appLogo()" height="30" alt="Dynamic Studio Logo">
            <img class="navbar-brand-minimized" :src="appLogo()" height="15" alt="Dynamic Studio Logo">
        </b-link>
        <SidebarToggler class="d-md-down-none" display="lg" />

        <b-navbar-nav class="ml-auto language-dropdown">
            <switch-lang class="language-dropdown" />
            <DefaultHeaderDropdownAccnt />
        </b-navbar-nav>
        <AsideToggler ref="asideTogglerButton" id="asideTogglerDesktop" class="d-none d-lg-block" />
        <AsideToggler ref="asideTogglerButton" id="asideTogglerMobile" class="d-lg-none" mobile />
    </AppHeader>
    <div class="app-body">
        <AppSidebar fixed>
            <SidebarHeader />
            <SidebarForm />
            <SidebarNav :navItems="navigation"></SidebarNav>
            <SidebarFooter />
            <SidebarMinimizer />
        </AppSidebar>
        <main class="main pt-3 pt-md-4">
            <!-- <Breadcrumb :list="list" /> -->
            <div class="container-fluid">
                <router-view></router-view>
                <slot name="page-content" />
            </div>
        </main>
        <AppAside fixed>
            <!--aside-->
            <DefaultAside />
        </AppAside>
    </div>
    <TheFooter>
        <custom-footer />
    </TheFooter>
    <slideout-panel></slideout-panel>
</div>
</template>

<script>
import { eventBus } from '../main'
import CustomFooter from './CustomFooter'
import nav from '@/_nav'
import LangNavbarAuth from '../views/auth/LangNavbarAuth'
import {
    Header as AppHeader,
    SidebarToggler,
    Sidebar as AppSidebar,
    SidebarFooter,
    SidebarForm,
    SidebarHeader,
    SidebarMinimizer,
    SidebarNav,
    Aside as AppAside,
    AsideToggler,
    Footer as TheFooter,
    Breadcrumb
} from '@coreui/vue'
import DefaultAside from './DefaultAside'
import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt'

export default {
    name: 'DefaultContainer',
    components: {
        'custom-footer': CustomFooter,
        'switch-lang': LangNavbarAuth,
        AsideToggler,
        AppHeader,
        AppSidebar,
        AppAside,
        TheFooter,
        Breadcrumb,
        DefaultAside,
        DefaultHeaderDropdownAccnt,
        SidebarForm,
        SidebarFooter,
        SidebarToggler,
        SidebarHeader,
        SidebarNav,
        SidebarMinimizer
    },
    data() {
        return {
            eventBus: eventBus,
            nav: nav.items,
            itemsCount: 42,
            opened: false,
        }
    },
    mounted: function () {
        // addEventListener
        window.addEventListener('click', this.handleClick);
    },
    beforeDestroy: function () {
        // Unregister the event listener before destroying this Vue instance.
        window.removeEventListener('click', this.handleClick)
    },
    methods: {
        handleClick: function (event) {
            if (event && event.target && (event.target.classList.contains('navbar-toggler') || event.target.classList.contains('navbar-toggler-icon'))) {
                this.opened = !this.opened
                if (this.opened) {
                    eventBus.$emit('get_badges_count', this.handleClick())
                }
            }
        },
    },
    computed: {
        brand_link: function () {
            return 'Identity'
        },
        navigation() {
            return this.getAuthorizedMenu()
        },
        name() {
            return this.$route.name
        },
        list() {
            return this.$route.matched.filter((route) => route.name || route.meta.label)
        },
    }
}
</script>

<style>
.slideout-panel {
    z-index: 1050;
}

body .app-header a.dropdown-item {
    min-width: 100%;
    width: 100%;
}
@media screen and (max-width: 767px) {
  .language-dropdown {
    margin-right: 0!important;
    margin-left: 0!important;
    flex-grow: 1;
    justify-content: space-between;
  }
}
</style>
