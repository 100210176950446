<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="icon-envelope" />
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item class="list-group-item-accent-danger bg-light font-weight-bold">
                    <q-icon name="delete" color="danger" />&nbsp;
                    <template v-if="ids.length > 1">{{ translate('delete_messages') }}</template>
                    <template v-if="ids.length === 1">{{ translate('delete_message') }}</template>
                </b-list-group-item>
                <b-list-group-item class="list-group-item-accent-info">
                     <template v-if="ids.length > 1">
                        {{ translate('you_are_about_to_delete_messages') }} {{ ids.length }} {{ translate('messages_low') }}.
                        <br />
                        {{ translate('deleting_messages') }}
                    </template>
                    <template v-if="ids.length === 1">
                        {{ translate('deleting_message') }}
                    </template>
                </b-list-group-item>
                <q-separator />
                <b-list-group-item class="list-group-item-accent-info">
                    <q-item class="p-0 full-width">
                        <q-item-section side>
                            <q-btn type="submit" @click="deleteMessages" no-caps color="danger">
                                <q-icon size="1rem" name="delete" class="mr-1" /> {{ translate('yes') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="secondary" class="text-dark">
                                <q-icon size="1rem" name="cancel" class="mr-1" /> {{ translate('no') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import { eventBus } from '../../../main'

export default {
    name: 'DeleteMessages',
    props: ['ids', 'delete_route'],
    data() {
        return {
            msg: null,
        }
    },
    methods: {
        deleteMessages: function () {
            // Delete selected message ids.
            var url = this.delete_route
            var ids = this.ids
            var data = {
                ids: ids
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            }
            
            axios.post(url, data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'delete_messages_' + response.data.msg + key
                    if (ids.length === 1) {
                        var message = 'delete_message_' + response.data.msg + key
                    }
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        eventBus.$emit('delete_selected_messages', ids)
                        this.$emit('closePanel', {
                            status: true
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    this.$toasted.error('An error has occured', {
                        duration: this.$toasted_duration
                    })
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>
