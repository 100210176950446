<template>
<div>
    <template>
        <b-list-group v-if="!upcoming_events" class="list-group-accent">
            <b-list-group-item class="list-group-item-accent-secondary bg-light text-center text-uppercase small text-muted">
                <span class="font-weight-bold">
                    {{ translate('no_upcoming_events') }}
                </span>
            </b-list-group-item>
        </b-list-group>
        <div class="card_scroll-upcoming-events">
            <b-list-group class="list-group-accent" v-for="(events,date) in upcoming_events" :key="'upcoming-events-' + date ">
                <b-list-group-item class="list-group-item-accent-secondary bg-light text-center text-uppercase small text-muted">
                    <span class="font-weight-bold">
                        {{ getSpecificDay(date) }}
                    </span>
                    <span class="float-right">{{ date }}</span>
                </b-list-group-item>
                <b-list-group-item v-for="(event,index) in events" :key="'upcoming-event-' + index" href="#" class="list-group-item-accent-primary list-group-item-divider">
                    <div>
                        {{ translate(event.event_type) }}
                        <strong>{{ event.service_name }}</strong>
                    </div>
                    <small class="text-muted">
                        <i class="icon-calendar"></i>&nbsp;&nbsp;{{ prettyTime(event.start_time) }} - {{ prettyTime(event.end_time) }}
                        <i class="icon-location-pin"></i>&nbsp;&nbsp;{{ event.location_name }}
                    </small>
                </b-list-group-item>
            </b-list-group>
        </div>
    </template>
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../main'

export default {
    name: 'UpcomingEventsWorker',
    data: function () {
        return {
            upcoming_events: null,
        }
    },
    mounted: function () {
        this.$on('transition', this.getUpcomingEvents())

        this.setScrollWrapperHeight()
    
        // Register the event listener when the Vue component is ready.
        window.addEventListener('resize', this.setScrollWrapperHeight)
    },
    beforeDestroy: function () {
        // Unregister the event listener before destroying this Vue instance.
        window.removeEventListener('resize', this.setScrollWrapperHeight)
    },
    methods: {
        setScrollWrapperHeight: _.debounce(function () {
            let windowHeight = this.$q.screen.height
            if (document.querySelector('.card_scroll-upcoming-events')) {
                let wrapperOffsetTop = document.querySelector('.card_scroll-upcoming-events').getBoundingClientRect().top
                document.querySelector('.card_scroll-upcoming-events').style.height = (windowHeight - wrapperOffsetTop) + 'px'
            }
        }, 200),
        getSpecificDay: function (input) {
            // 1. Today's Upcoming Events.
            var today = new Date()
            var formatted_today = this.formatDate(today)
            
            if (formatted_today === input) {
                return this.translate('today')
            }

            // 2. Tomorrow's Upcoming Events.
            var tomorrow = new Date(today)
            tomorrow.setDate(tomorrow.getDate() + 1)
            var formatted_tomorrow = this.formatDate(tomorrow)
            
            if (formatted_tomorrow === input) {
                return this.translate('tomorrow')
            }

            // 3. The day after tomorrow's Upcoming Events.
            var after_tomorrow = new Date(tomorrow)
            after_tomorrow.setDate(tomorrow.getDate() + 1)
            var formatted_after_tomorrow = this.formatDate(after_tomorrow)
            
            if (formatted_after_tomorrow === input) {
                return this.translate('day_after_tomorrow')
            }
        },
        formatDate: function (date) {
            var year = date.getFullYear() < 10 ? '0' + date.getFullYear() : date.getFullYear()
            var month = date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
            var date = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
            var formatted_date = year + '-' + month + '-' + date
            return formatted_date
        },
        prettyTime: function (timestring) {
            return timestring.slice(0,-3)
        },
        getUpcomingEvents: function () {
            // Get Upcoming Events.
            var url = baseUrl + 'upcoming-events'
            var headers = {
                "Authorization": "Bearer " + this.accessToken(),
            }
            axios.get(url, {
                headers: headers
            }).then(response => {
                if (response.data.status) {
                    this.upcoming_events = response.data.upcoming_events

                    var badge_count = response.data.badge_count ? response.data.badge_count : null

                    // Update badge count.
                    eventBus.$emit('update_info_panel_badge_count_upcoming_events', {count: badge_count})
                }
                if (_.isEmpty(this.upcoming_events)) {
                    this.upcoming_events = null
                }
            }).catch(error => {
                // console.log(error)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.card_scroll-upcoming-events {
    max-height: 74vh;
    overflow-y: auto;
}
</style>
