<template>
<div>
    <q-item class="p-2 list-group-item-accent-primary text-center">
        <q-item-section>
            <q-btn @click.stop.prevent="showSendMessagePanel" no-caps color="blue-2" class="text-dark full-width">
                <i class="icon-envelope mr-1"></i> {{ translate('send_message') }}
            </q-btn>
        </q-item-section>
    </q-item>
    <b-list-group>
        <b-list-group-item class="list-group-item-accent-secondary bg-light text-center text-uppercase small text-muted">
            {{ translate('app_messages') }}
        </b-list-group-item>
        <q-tabs dense indicator-color="primary" clickable hoverable no-caps v-model="messages_tab" class="no-controls small" align="left">
            <q-tab name="received">
                <span><i class="fas fa-inbox text-positive mr-2 fa-lg"></i> {{ translate('received_messages') }}</span>
            </q-tab>
            <q-tab name="sent">
                <span><i class="fas fa-paper-plane text-danger mr-2 fa-lg"></i> {{ translate('sent_messages') }}</span>
            </q-tab>
        </q-tabs>
        <q-tab-panels v-model="messages_tab">
            <q-tab-panel class="p-0" name="received">
                <received />
            </q-tab-panel>
            <q-tab-panel class="p-0" name="sent">
                <sent />
            </q-tab-panel>
        </q-tab-panels>
    </b-list-group>
</div>
</template>

<script>
import { eventBus } from '../../main'
import SendMessage from './forms/SendMessage'
import ReceivedMessages from './forms/ReceivedMessages'
import SentMessages from './forms/SentMessages'

export default {
    name: 'MessagesWorker',
    components: {
        'received': ReceivedMessages,
        'sent': SentMessages
    },
    data: function () {
        return {
            messages_tab: 'received',
        }
    },
    methods: {
        showSendMessagePanel: function () {
            const panelInstance = this.$showPanel({
                component: SendMessage,
                props: {}
            })
        },
    }
}
</script>
